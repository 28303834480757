<template>
  <div id="data-list-list-view" class="data-list-container">
    <!-- <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" /> -->
    <div class="vx-row">
      <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="ActivityIcon"
          icon-right
          statistic="255"
          statisticTitle="Today All" />
      </div>
      <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="LoaderIcon"
          icon-right
          statistic="55"
          statisticTitle="Pending"
          color="warning" />
      </div>
      <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="CheckCircleIcon"
          icon-right
          statistic="75"
          statisticTitle="Accepted"
          color="success" />
      </div>
      <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="AlertOctagonIcon"
          icon-right
          statistic="13"
          statisticTitle="Rejected"
          color="danger" />
      </div>
      <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="TruckIcon"
          icon-right
          statistic="105"
          statisticTitle="Delivered"
          color="success" />
      </div>
      <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="XOctagonIcon"
          icon-right
          statistic="7"
          statisticTitle="Not Delivered"
          color="danger" />
      </div>
    </div>
    <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8" actionButtons @refresh="resetColFilters" @remove="resetColFilters">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Status</label>
          <v-select :options="statusOptions" :clearable="false" v-model="filter.status" class="mb-4 md:mb-0" :reduce="el => el.value" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Market type</label>
          <v-select :options="marketOptions" :clearable="false" v-model="filter.delivery_type" class="mb-4 md:mb-0" :reduce="el => el.value" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Date from</label>
          <datepicker monday-first clear-button :disabledDates="disabledDates" placeholder="Select Date" v-model="filter.from" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Date until</label>
          <datepicker monday-first clear-button :disabledDates="disabledDates" placeholder="Select Date" v-model="filter.until" />
        </div>
      </div>
    </vx-card>
    <vs-table ref="table" :max-items="filter.limit" :data="filteredOrders"> <!-- multiple v-model="selected"-->
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 items-per-page-handler mr-auto">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentx * filter.limit - (filter.limit - 1) }} - {{ orders.pagination.all - currentx * filter.limit > 0 ? currentx * filter.limit : orders.pagination.all }} of {{ orders.pagination.all }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="opt in perPageOptions" :key="opt" @click="filter.limit=opt">
              <span>{{ opt }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">Search by: {{ searchBy.label }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="search in searchOptions" :key="search.value" @click="searchBy=search">
              <span>{{ search.label }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="inline-block relative mb-4">
          <vs-input placeholder="Search" class="custom-search-input" v-model="searchQuery" />
          <feather-icon v-if="searchQuery.length" icon="TrashIcon" svgClasses="w-4 h-4 hover:text-danger stroke-current" style="position: absolute !important; top: 14px; right: 13px" class="ml-2" @click.stop="searchQuery = ''" />
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="name">Code</vs-th>
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="user_phone">Phone</vs-th>
        <vs-th sort-key="category">Market</vs-th>
        <vs-th sort-key="popularity">User Rate</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th sort-key="address" class="truncate w-16">Address</vs-th>
        <vs-th sort-key="price">Price</vs-th>
        <vs-th sort-key="createdAt">Date created</vs-th>
        <vs-th>Action</vs-th>
        <vs-th>View</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td><p class="product-name font-medium truncate">#{{ data[indextr].code }}</p></vs-td>
              <vs-td><p class="product-name font-medium truncate">{{ data[indextr].user_name }}</p></vs-td>
              <vs-td><p class="product-name font-medium truncate">{{ data[indextr].user_phone }}</p></vs-td>
              <vs-td><vs-chip :color="data[indextr].delivery_type === 'market' ? 'primary' : 'success'" class="product-order-status">{{ data[indextr].delivery_type | title }}</vs-chip></vs-td>
              <vs-td><vs-progress :percent="Number(data[indextr].rating)*100/5" :color="getPopularityColor(Number(data[indextr].rating)*100/5)" class="shadow-md" /></vs-td>
              <vs-td><vs-chip :color="getOrderStatusColor(data[indextr].status)" class="product-order-status">{{ data[indextr].status | title }}</vs-chip></vs-td>
              <vs-td><p class="product-name font-medium truncate w-32" :title="data[indextr].address">{{ data[indextr].address }}</p></vs-td>
              <vs-td>
                <div class="product-price font-semibold">
                  <feather-icon v-if="data[indextr].payment_type === 'cart'" icon="CreditCardIcon" svgClasses="mr-2 w-5 h-5 text-primary" />
                  <feather-icon v-else icon="DollarSignIcon" svgClasses="mr-2 w-5 h-5 text-primary" />
                  <span style="vertical-align: top">{{ data[indextr].total_price }}m</span>
                </div>
              </vs-td>
              <vs-td><p class="product-name font-medium truncate">{{ data[indextr].createdAt }}</p></vs-td>
              <vs-td>
                <template v-if="data[indextr].status === 'pending'">
                  <feather-icon icon="CheckSquareIcon" svgClasses="w-5 h-5 text-primary" title="Accept" class="mr-1" @click.stop="confirm('accept', tr.code, tr.uuid)" />
                  <feather-icon icon="XSquareIcon" svgClasses="w-5 h-5 text-danger" title="Reject" class="" @click.stop="confirmReject(tr.code, tr.uuid)" />
                </template>
                <template v-if="data[indextr].status === 'accepted'">
                  <feather-icon icon="TruckIcon" svgClasses="w-5 h-5 text-primary" title="Send to customer" class="mr-1" @click.stop="sentOrder(tr.uuid)" />
                </template>
                <template v-if="data[indextr].status === 'sent'">
                  <feather-icon icon="HomeIcon" svgClasses="w-5 h-5 text-primary" title="Delivered" class="mr-1" @click.stop="confirm('deliver', tr.code, tr.uuid)" />
                  <feather-icon icon="XCircleIcon" svgClasses="w-5 h-5 text-danger" title="Not Delivered" class="" @click.stop="confirmNotDeliver(tr.code, tr.uuid)" />
                </template>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <router-link :to="{name: 'orderShow', params: {id: data[indextr].uuid}}" class="text-dark">
                  <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" />
                </router-link>
                <!-- <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editData(tr)" /> -->
                <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.id)" /> -->
              </vs-td>

            </vs-tr>
          </tbody>
        </template>
    </vs-table>
    <vs-row class="mt-4">
      <vs-pagination :total="pages" :max="9" v-model="currentx" @change="paginateTo"></vs-pagination>
    </vs-row>
    <vs-popup :active.sync="activePrompt" variant="danger" :title="isRejecting ? 'Rejecting order' : 'Edit order'">
      <div class="con-exemple-prompt">
        
        <template v-if="isRejecting || isNotDelivered">
          <p class="mb-3">Please enter reason:</p>
          <vs-textarea v-validate="'required'" name="reason" v-model="note" placeholder="Reason" />
          <span class="text-danger text-sm" v-show="errors.has('reason')">{{ errors.first('reason') }}</span>
          <vs-row class="mt-6">
            <vs-button @click="rejectOrder" color="danger">Reject</vs-button>
            <vs-button @click="cancel" color="primary" type="border" class="ml-auto">Cancel</vs-button>
          </vs-row>
        </template>

        <template v-else>
          <v-select v-validate="'required'" label="name" :options="carriers" name="carrier" placeholder="Select one" v-model="selectedCarrier" :reduce="carrier => carrier.id" />
          <span class="text-danger text-sm" v-show="errors.has('carrier')">{{ errors.first('carrier') }}</span>
          <vs-row class="mt-24">
            <vs-button @click="submitSent">Sent</vs-button>
            <vs-button @click="cancel" color="danger" type="border" class="ml-auto">Cancel</vs-button>
          </vs-row>
        </template>

      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'


export default {
  components: {
    vSelect,
    Datepicker,
    StatisticsCardLine
  },
  data () {
    return {
      searchBy: { label: 'Code', value: 'code' },
      orders: { pagination: {}, data: [] },
      filter: {
        limit: 15,
        offset: 0,
        code: '',
        user_phone:'',
        status: '',
        delivery_type: '',
        from: null,
        until: null
      },
      searchOptions: [
        { label: 'Phone', value: 'user_phone' },
        { label: 'Code', value: 'code' }
      ],
      marketOptions: [
        { label: 'All', value: '' },
        { label: 'Market', value: 'market' },
        { label: 'Express', value: 'express' }
      ],
      statusOptions: [
        { label: 'All', value: '' },
        { label: 'Pending', value: 'pending' },
        { label: 'Accepted', value: 'accepted' },
        { label: 'Sent', value: 'sent' },
        { label: 'Rejected', value: 'rejected' },
        { label: 'Delivered', value: 'delivered' },
        { label: 'Not delivered', value: 'not_delivered' }
      ],
      currentx: 1,
      perPageOptions: [4, 10, 15, 20, 50],
      isMounted: false,
      // addNewDataSidebar: false,
      // sidebarData: {},
      searchQuery: '',
      activeOrder: '',
      carriers: [],
      selectedCarrier: '',
      activePrompt: false,
      isRejecting: '',
      isNotDelivered: '',
      note: ''
    }
  },
  watch: {
    filter: {
      handler () { this.fetchData() },
      deep: true
    },
    searchQuery (newVal) {
      if (newVal.length > 1) { this.filter[this.searchBy.value] = newVal }
      if (!newVal) { this.filter[this.searchBy.value] = '' }
    }
  },
  computed: {
    pages ()          { return Math.ceil(this.orders.pagination.all / this.filter.limit) },
    filteredOrders () { return this.orders.data },
    disabledDates () {
      // const from = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay())
      const from = new Date()
      return { from }
    }
  },
  methods: {
    sentOrder (id) {
      this.activeOrder = id
      this.activePrompt = true
      this.isRejecting = false
      this.isNotDelivered = false
    },
    submitSent () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/orders/edit/${this.activeOrder}`, {
            status: 'sent',
            carrierId: this.selectedCarrier
          }).then(() => {
            this.fetchData()
            this.activePrompt = false
            this.activeOrder = ''
            this.isRejecting = ''
          }).catch(err => (err))
        }
      })
    },
    confirmReject (code, id) {
      this.activeOrder = id
      this.isRejecting = code
      this.activePrompt = true
    },
    confirmNotDeliver (code, id) {
      this.activeOrder = id
      this.isNotDelivered = code
      this.activePrompt = true
    },
    confirm (e, code, uuid) {
      this.activeOrder = uuid
      const color = e === 'reject' || e === 'not_delivered' ? 'danger' : 'primary'
      const title = `Confirm ${e}`
      let text = ''
      let accept = ''
      let acceptText = ''
      if (e === 'accept') {
        text = `You are about to accept order ${code}`
        accept = this.acceptOrder
        acceptText = 'Accept'
      }
      if (e === 'deliver') {
        text = `You are about to deliver order ${code}`
        accept = this.deliverOrder
        acceptText = 'Submit'
      }
      this.$vs.dialog({ type: 'confirm', color, title, text, accept, acceptText })
    },
    acceptOrder () {
      this.$http.patch(`/orders/edit/${this.activeOrder}`, { status: 'accepted' })
        .then(() => { this.fetchData() })
        .catch(err => (err))
    },
    deliverOrder () {
      this.$http.patch(`/orders/edit/${this.activeOrder}`, { status: 'delivered' })
        .then(() => { this.fetchData() })
        .catch(err => (err))
    },
    rejectOrder () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/orders/edit/${this.activeOrder}`, {
            status: this.isRejecting ? 'rejected' : 'not_delivered',
            note: this.note
          }).then(() => {
            this.fetchData()
            this.activePrompt = false
            this.activeOrder = ''
            this.isRejecting = ''
            this.note = ''
          }).catch(err => (err))
        }
      })
    },
    cancel () {
      this.activePrompt = false
      this.activeOrder = ''
      this.isRejecting = ''
    },
    resetColFilters () {
      this.filter = {
        limit: 15,
        offset: 0,
        code: '',
        user_phone:'',
        status: '',
        delivery_type: '',
        from: null,
        until: null
      }
    },
    paginateTo () {
      this.filter.offset = this.filter.limit * (this.currentx - 1)
      this.fetchData()
    },
    async fetchData () {
      await this.$http.get('/wholesalers/orders', { params: this.filter }).then(res => {
        this.orders = res.data
      })
    },
    async fetchCarriers () {
      await this.$http.get('/carriers')
        .then(res => { this.carriers = res.data.data })
        .catch(err => (err))
    },
    deleteData (id) {
      this.$store.dispatch('dataList/removeItem', id).catch(err => { return (err) })
    },
    editData (data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    getOrderStatusColor (status) {
      if (status === 'pending')   return 'warning'
      if (status === 'accepted') return 'primary'
      // if (status === 'sent') return 'grey'
      if (status === 'delivered') return 'success'
      if (status === 'rejected') return 'danger'
      if (status === 'not_delivered')  return 'danger'
      return 'primary'
    },
    getPopularityColor (num) {
      if (num > 80)  return 'success'
      if (num > 60)  return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50)  return 'danger'
      return 'primary'
    }
    // toggleDataSidebar (val = false) {
    //   this.addNewDataSidebar = val
    // }
  },
  async created () {
    await this.fetchData()
    await this.fetchCarriers()
    this.isMounted = true
  },
  mounted () {
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
  .custom-search-input {
    .vs-con-input {
      input {
        padding: 13px !important;
        border-radius: 50px;
      }
    }
  }
}
.vdp-datepicker {
  // input {
  //   padding: 13.5px 16px;
  //   border-radius: 25px;
  //   width: 130px;
  // }
  .vdp-datepicker__clear-button {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 24px;
  }
}
</style>
